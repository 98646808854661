import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Popover from "@material-ui/core/Popover";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi, credentials } from "utils/headApi";
// import { MdUpdate } from "react-icons/md";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

// import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

import logo from "assets/img/examples/apple-insha-bangles-icon.png";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// const useStyle = makeStyles(styles);

export default function InshaProductList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(0);
  const [loginModal, setLoginModal] = React.useState(false);
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const [prodDetails, setProdDetails] = React.useState("");
  const [prodDetailsChild, setProdDetailsChild] = React.useState("");
  const [prodDetailsChild1, setProdDetailsChild1] = React.useState("");
  const [prodDetailsChild2, setProdDetailsChild2] = React.useState("");
  const [prodDetailsRaw, setProdDetailsRaw] = React.useState("");
  const [prodDetailsSets, setProdDetailsSets] = React.useState("");
  const [prodDetailsBox, setProdDetailsBox] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();

  // const classe = useStyle();

  const fetchData = async () => {
    setpage(page + 1);
    axios
      .get(localApi + `/product/list/byLimit?admin_id=1&page=${page}&size=4`, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((res) => setProdList([...prodList, ...res.data]));
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  var metamt = 0;
  var metprice = 0;
  var boxmetgram = 0;
  var metgram = 0;
  var pltprice = 0;
  var boxpltamt = 0;
  var metgramamt = 0;

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Making Charge</title>
        <meta
          name="description"
          content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)."
        />
        <meta
          name="keywords"
          content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs"
        />
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
      />
      <Parallax
        // image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Making List</h1>
              <h4 style={{ color: "white" }}>
                Each INSHA{"'"}S piece is crafted to give it a signature finish.
                Yet, you get a wide range of gold plated jewellery choices with
                the making charges at a good price.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "black" }}
                  />
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              You may also be interested in:
            </h3>
            <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            >
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => {
                      // pltprice = pltprice + prod.plating.price
                      boxpltamt = prod.sets.pcs * prod.box.box;
                      metprice = metprice + prod.rawPrice.price;
                      metgram = prod.metalGram;
                      metamt = prod.rawPrice.price + prod.rawPrice.different;
                      boxmetgram =
                        metgram * prod.sets.pcs * prod.box.box * metamt;
                      metgramamt = metgram * metamt;
                      return (
                        <>
                          <GridItem sm={6} md={3} key={prod.id}>
                            <Card product>
                              <CardHeader image>
                                <img src={prod.productimage[0].cpanelPath} alt="..." />
                                {/* <p
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "90%",
                                                                    top: "90%",
                                                                    transform: "translate(-50%, -50%)",
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    id="tooltip-tops"
                                                                    title="Share to WhatsApp"
                                                                    placement="top"
                                                                    classes={{ tooltip: classes.tooltip }}
                                                                >
                                                                    <a
                                                                        href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp
                                                                            }&text=https://indianbangles.in/bangles-product?id=${prod.id
                                                                            }%20${prod.banglessizemm.description
                                                                            }%20${prod.plating.description.slice(
                                                                                8
                                                                            )}&app_absent=0`}
                                                                    >
                                                                        {imgApi + "" + prod.design1.image1 !==
                                                                            imgApi + "" + "/crop_img/null" ? (
                                                                            <TiArrowForward size={50} color="#00acc1" />
                                                                        ) : imgApi + "" + prod.design2.image2 !==
                                                                            imgApi + "" + "/crop_img/null" ? (
                                                                            <TiArrowForward size={50} color="#00acc1" />
                                                                        ) : imgApi + "" + prod.design3.image3 !==
                                                                            imgApi + "" + "/crop_img/null" ? (
                                                                            <TiArrowForward size={50} color="#00acc1" />
                                                                        ) : imgApi + "" + prod.design4.image4 !==
                                                                            imgApi + "" + "/crop_img/null" ? (
                                                                            <TiArrowForward size={50} color="#00acc1" />
                                                                        ) : imgApi + "" + prod.design5.image5 !==
                                                                            imgApi + "" + "/crop_img/null" ? (
                                                                            <TiArrowForward size={50} color="#00acc1" />
                                                                        ) : imgApi + "" + prod.design6.image6 !==
                                                                            imgApi + "" + "/crop_img/null" ? (
                                                                            <TiArrowForward size={50} color="#00acc1" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </a>
                                                                </Tooltip>
                                                            </p> */}

                                {/* {prod.design1.img1active === 1 ? (
                                                                <img src={imgApi + "" + prod.design1.image1} alt="img1active" />
                                                            ) : prod.design2.img2active === 1 ? (
                                                                <img src={imgApi + "" + prod.design2.image2} alt="img2active" />
                                                            ) : prod.design3.img3active === 1 ? (
                                                                <img src={imgApi + "" + prod.design3.image3} alt="img3active" />
                                                            ) : prod.design4.img4active === 1 ? (
                                                                <img src={imgApi + "" + prod.design4.image4} alt="img4active" />
                                                            ) : prod.design5.img5active === 1 ? (
                                                                <img src={imgApi + "" + prod.design5.image5} alt="img5active" />
                                                            ) : prod.design6.img6active === 1 ? (
                                                                <img src={imgApi + "" + prod.design6.image6} alt="img6active" />
                                                            ) : (
                                                                ""
                                                            )} */}
                                <p
                                  style={{
                                    position: "absolute",
                                    top: "65%",
                                    left: "50%",
                                    width: "65px",
                                    height: "65px",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <img src={logo} alt="logo1" />
                                  {/* {imgApi + "" + prod.design1.image1 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <img src={logo} alt="logo1" />
                                                                ) : imgApi + "" + prod.design2.image2 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <img src={logo} alt="logo2" />
                                                                ) : imgApi + "" + prod.design3.image3 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <img src={logo} alt="logo3" />
                                                                ) : imgApi + "" + prod.design4.image4 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <img src={logo} alt="logo4" />
                                                                ) : imgApi + "" + prod.design5.image5 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <img src={logo} alt="logo5" />
                                                                ) : imgApi + "" + prod.design6.image6 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <img src={logo} alt="logo6" />
                                                                ) : (
                                                                    ""
                                                                )} */}
                                </p>
                              </CardHeader>
                              <h6
                                className={classNames(
                                  classes.cardCategory,
                                  classes.textRose
                                )}
                              >
                                {prod.category.title} <br /> {prod.parent.title}{" "}
                                {prod.itemCodeNumber} - {prod.id} <br />
                                <Button
                                  round
                                  color="rose"
                                  onClick={(event) => {
                                    setAnchorElBottom(event.currentTarget);
                                    setProdDetails(prod);
                                    setProdDetailsChild(
                                      prod.productchild.map((lbr) => {
                                        return (
                                          <>
                                            <span>
                                              {lbr.title + " " + lbr.price}
                                            </span>
                                            <br />
                                          </>
                                        );
                                      })
                                    );
                                    setProdDetailsChild1(
                                      prod.productchild
                                        .reduce((a, b) => a + b.price, 0)
                                        .toFixed(2)
                                    );
                                    setProdDetailsChild2(
                                      prod.productchild
                                        .reduce(
                                          (a, b) =>
                                            a +
                                            b.price *
                                              prod.sets.pcs *
                                              prod.box.box,
                                          0
                                        )
                                        .toFixed(2) + "/-"
                                    );
                                    setProdDetailsRaw(prod.rawPrice);
                                    setProdDetailsSets(prod.sets);
                                    setProdDetailsBox(prod.box);
                                  }}
                                >
                                  More Details
                                </Button>
                              </h6>
                            </Card>
                            {/* <Card>
                              <CardBody>
                                <div className={classes.price}>
                                  {prod.productChild.map((lbr) => {
                                    return (
                                      <>
                                        <span>
                                          {lbr.title + " " + lbr.price}
                                        </span>
                                        <br />
                                      </>
                                    );
                                  })}
                                  <span style={{ color: "red" }}>
                                    Labour/Box.{" "}
                                    {prod.productChild
                                      .reduce((a, b) => a + b.price, 0)
                                      .toFixed(2)}
                                    {"/"}
                                    {prod.productChild
                                      .reduce(
                                        (a, b) =>
                                          a +
                                          b.price *
                                            prod.productSets.pcs *
                                            prod.productBox.box,
                                        0
                                      )
                                      .toFixed(2) + "/-"}
                                  </span>
                                  <br />
                                  <span>
                                    Metal Price {prod.productRawPrice.price} +{" "}
                                    {prod.productRawPrice.different} = {metamt}{" "}
                                    <br />
                                    Metal Gram {prod.metalGram} <br />
                                    Ready Gram {prod.readyGram}
                                  </span>
                                  <br />
                                  <div className={classes.cardDescriptions}>
                                    {(
                                      prod.readyGram * prod.productSets.pcs
                                    ).toFixed(4)}{" "}
                                    Avg Gram Of {prod.productSets.setDesc}{" "}
                                    {prod.productBox.boxDesc}
                                  </div>{" "}
                                  <span style={{ color: "red" }}>
                                    Metal Amount{" "}
                                    {metgramamt.toFixed(2) +
                                      "/" +
                                      boxmetgram.toFixed(2)}
                                    /-
                                  </span>
                                </div>
                              </CardBody>
                            </Card> */}
                          </GridItem>
                        </>
                      );
                    })}
                  <Popover
                    classes={{
                      paper: classPop.popover,
                    }}
                    open={Boolean(anchorElBottom)}
                    anchorEl={anchorElBottom}
                    onClose={() => setAnchorElBottom(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classPop.popoverBody}>
                      <span style={{ color: "yellow" }}>
                        Product Id: {prodDetails.id}
                      </span>
                      <br />
                      {prodDetailsChild}
                      <span style={{ color: "yellow" }}>
                        Labour/Box. {prodDetailsChild1}
                        {"/"}
                        {prodDetailsChild2}
                      </span>
                      <br />
                      <span>
                        Metal Price {prodDetailsRaw.price} +{" "}
                        {prodDetailsRaw.different} = {metamt} <br />
                        Metal Gram {prodDetails.metalGram} <br />
                        Ready Gram {prodDetails.readyGram}
                      </span>
                      <br />
                      <div className={classes.cardDescriptions}>
                        {(prodDetails.readyGram * prodDetailsSets.pcs).toFixed(
                          4
                        )}{" "}
                        Avg Gram Of {prodDetailsSets.setDesc}{" "}
                        {prodDetailsBox.boxDesc}
                      </div>{" "}
                      <span style={{ color: "yellow" }}>
                        Metal Amount{" "}
                        {metgramamt.toFixed(2) + "/" + boxmetgram.toFixed(2)}
                        /-
                      </span>
                    </div>
                  </Popover>
                </GridContainer>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
